import * as React from "react"
import Layout from "../components/layout"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate } from "gatsby"
import ProductCard from "../components/productCard"
import SearchInput from "../components/searchInput"
import ReactPaginate from "react-paginate"

const CollectionsName = [
  "topsAll",
  "topsCrops",
  "topsCorset",
  "topsShort",
  "topsLong",
  "topsSleeveless",
  "topsBodySuits",
]

const ItemsPerPage = 24

const SearchPage = ({ data, location }) => {
  const [filteredData, setFilteredData] = useState(null)
  const [itemOffset, setItemOffset] = useState(0)
  const [pageSelected, setPageSelected] = useState(0)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const pageFromUrl = parseInt(params.get("page")) || 1
    if (filteredData) {
      setItemOffset(((pageFromUrl - 1) * ItemsPerPage) % filteredData?.length)
    }
    setPageSelected(pageFromUrl - 1)
  }, [filteredData])

  useEffect(() => {
    let searchData = []
    CollectionsName.map(collection => {
      if (data[collection]) {
        searchData.push(...data[collection].edges.map(item => item.node.data))
      }
    })

    const searchQuery = new URLSearchParams(location.search).get("q") || ""
    if (searchQuery) {
      handleSearchData({ searchQuery, searchData })
    } else {
      setFilteredData(searchData)
    }
  }, [location.search])

  const handleSearchData = ({ searchQuery, searchData }) => {
    const keywords = searchQuery.toLowerCase().trim().split(/\s+/)

    const filtered = searchData.filter(item => {
      const title = item.product_title.toLowerCase()

      if (title.includes(searchQuery.toLowerCase().trim())) return true

      return keywords.every(word => title.includes(word))
    })

    setFilteredData(filtered)
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setItemOffset((event.selected * ItemsPerPage) % filteredData?.length)
    setPageSelected(event.selected)

    const url = new URL(window.location)
    url.searchParams.set("page", newPage)

    const searchQuery = url.searchParams.get("q")
    if (searchQuery) {
      url.searchParams.set("q", searchQuery)
    }

    navigate(`${url.pathname}?${url.searchParams.toString()}`, {
      replace: true,
    })

    document
      .getElementById("search-content")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout
      location={location}
      title={data.site.siteMetadata?.title || `Title`}
    >
      <Helmet>
        <link rel="canonical" href="https://www.collectivedress.com/search" />
        <title>Search | Collective Dresses</title>
        <meta
          name="description"
          content="Find your perfect dress! Search by category, color, and more across our entire collection."
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start min-h-screen mt-10 w-full">
        <SearchInput
          data={data}
          CollectionsName={CollectionsName}
          location={location}
          setPageSelected={setPageSelected}
          handleSearchData={handleSearchData}
        />

        <div id="search-content" className="search-content mt-8">
          {filteredData ? (
            <>
              {filteredData.length > 0 ? (
                <div className="flex flex-col justify-center gap-4">
                  <ProductCard
                    data={filteredData?.slice(
                      itemOffset,
                      itemOffset + ItemsPerPage
                    )}
                  />
                  <div className="flex flex-col justify-center items-center">
                    <ReactPaginate
                      breakLabel=".."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(filteredData?.length / ItemsPerPage)}
                      previousLabel="<"
                      forcePage={pageSelected}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="page-num"
                      previousLinkClassName="page-num pagination-pre"
                      nextLinkClassName="page-num paingation-next"
                      activeLinkClassName="active"
                    />

                    <p className="text-center">
                      Showing items {itemOffset + 1} -{" "}
                      {itemOffset + ItemsPerPage > filteredData?.length
                        ? filteredData?.length
                        : itemOffset + ItemsPerPage}{" "}
                      of {filteredData?.length}
                    </p>
                  </div>
                </div>
              ) : (
                <p class="text-xl font-bold text-center py-6">
                  No Products Found
                </p>
              )}
            </>
          ) : (
            <p class="text-xl font-bold text-center py-6">Loading...</p>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    topsAll: allAirtable(limit: 6500, filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsCrops: allAirtable(limit: 3500, filter: { table: { eq: "dress_maxi" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsCorset: allAirtable(
      limit: 2500
      filter: { table: { eq: "dress_midi" } }
    ) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsShort: allAirtable(limit: 4500, filter: { table: { eq: "dress_mini" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsLong: allAirtable(limit: 4500, filter: { table: { eq: "dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    topsSleeveless: allAirtable(
      limit: 2500
      filter: { table: { eq: "dress_bridesmaid" } }
    ) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
}
`
