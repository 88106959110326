import * as React from "react"
import { Link } from "gatsby";

const ProductCard = ({ data, name, collection }) => {
  const getRecommendation = data => {
    return data.map((item, index) => {
      return item.product_title != null ? (
        <div className="recommendation-product">
          <a
            class="hover:opacity-75"
            href={`/products/cd-${item.product_title
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "-")}`}
          >
            <img
              loading="lazy"
              fetchpirority="low"
              src={item.product_image}
              alt={item.product_title}
            />
          </a>

          <Link
            className="product-link "
            href={`/products/cd-${item.product_title
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "-")}`}
          >
            <p className="font-bold hover:opacity-75">
              CD-
              {item.product_title.split(/\b/).reverse().join("")}
            </p>
          </Link>

          {/* <span>{data.company}</span> */}
          <p className="mb-2">{item.product_price}</p>
        </div>
      ) : null
    })
  }

  return (
    <div className="home-recommendation-container pt-4 pb-0 mt-4">
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {data != null ? getRecommendation(data) : null}
      </div>
    </div>
  )
}

export default ProductCard
